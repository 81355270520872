<template>
    <section class="section">
        <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="90vh">
            <v-container class="mt-10" style="max-height: 65vh">
                <SchedulesFilter @setQuery="getScheduleList" v-model="isFiltersActive" />

                <div id="user-list" class="mt-8">
                    <!-- actions -->
                    <div class="d-flex">
                        <!-- search -->
                        <v-text-field
                            expanded
                            v-model="search"
                            @change="searchSchedules()"
                            :placeholder="$t('Search')"
                            outlined
                            hide-details
                            dense
                            :prepend-inner-icon="icons.mdiMagnify"
                            class="user-search me-3 mb-4">
                        </v-text-field>

                        <v-btn @click.stop="isFiltersActive = !isFiltersActive" outlined color="grey-light">
                            <v-icon color="grey">
                                {{ icons.mdiFilterVariant }}
                            </v-icon>
                        </v-btn>
                    </div>

                    <v-card class="mt-8">
                        <template>
                            <v-data-table
                                show-select
                                calculate-widths
                                :items-per-page="5"
                                :footer-props="{
                                    'items-per-page-options': [5, 10, 15, 20],
                                    'items-per-page-text': $t('RowsPerPage'),
                                }"
                                :header-props="{ 'sort-by-text': $t('SortBy') }"
                                v-model="selected"
                                :headers="tableColumns"
                                :items="scheduleListTable"
                                :server-items-length="totalScheduleListTable"
                                :loading="isLoading"
                                :options.sync="options"
                                @update:page="paginationHandle"
                                height="400"
                                fixed-header>
                                <template #[`item.id`]="{ item }">
                                    <div class="extend-column d-flex flex-column ms-3">
                                        <router-link
                                            :to="{
                                                name: 'StopTimesProfile',
                                                params: { id: item.id, goToSchedule: true },
                                            }"
                                            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                                            {{ item.id }}
                                        </router-link>
                                    </div>
                                </template>
                                <template #[`item.stop.name`]="{ item }">
                                    <div class="extend-column d-flex flex-column ms-3">
                                        <span class="text--primary">
                                            {{ item.stop.name }}
                                            <span v-if="item.stop.city">({{ item.stop.city }})</span>
                                        </span>
                                    </div>
                                </template>
                                <template #[`item.actions`]="{ item }">
                                    <v-btn
                                        @click="
                                            $router.push({
                                                name: 'StopTimesProfile',
                                                params: { id: item.id, goToSchedule: true },
                                            })
                                        "
                                        icon>
                                        <v-icon large>{{ icons.mdiChevronRight }}</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </template>
                    </v-card>
                </div>
                <!-- ALERTAS DE SUCESSO E ERRO A ELIMINAR PARQUES-->
                <v-snackbar :timeout="2000" top v-model="isSuccess" color="white">
                    <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
                    <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
                    <p class="text-center black--text">{{ $t('Alerts.DeleteSuccess') }}</p>
                </v-snackbar>
                <v-snackbar :timeout="2000" top v-model="isError" color="white">
                    <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/failed.png"></v-img>
                    <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Error') }}</p>
                    <p class="text-center black--text">{{ $t('Alerts.ErrorMessage') }}</p>
                </v-snackbar>
            </v-container>
        </v-sheet>
        <v-app-bar height="80" absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">
            <v-container class="d-flex">
                <v-toolbar-title>
                    <p class="d-none d-md-flex d-lg-flex d-xl-flex text-h5 font-weight-semibold black--text mt-1">
                        {{ $t('SchedulesList') }}
                    </p>
                    <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                        {{ $t('SchedulesList') }}
                    </p>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-dialog v-if="selected.length > 0" transition="dialog-top-transition" max-width="600">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="error" v-bind="attrs" v-on="on">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                        </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                        <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>

                        <v-card>
                            <v-card-text class="text-center">
                                <p class="title font-weight-semibold black--text mt-12">{{ $t('Alerts.Sure') }}</p>
                            </v-card-text>
                            <v-card-text class="text-center">
                                <p v-if="selected.length === 1" class="body-2">
                                    {{ $t('Alerts.SureDeleteStopTime') }}
                                    <strong class="black--text">{{
                                        selected.map((entry) => entry.id).join(', ')
                                    }}</strong>
                                    ?<br />
                                    {{ $t('Alerts.Irreversible') }}
                                </p>
                                <p v-else class="body-2">
                                    {{ $t('Alerts.SureDeleteStopTime') }}
                                    <strong class="black--text">{{
                                        selected.map((entry) => entry.id).join(', ')
                                    }}</strong>
                                    ?<br />
                                    {{ $t('Alerts.Irreversible') }}
                                </p>
                            </v-card-text>

                            <v-card-actions class="justify-end mt-4">
                                <v-btn @click="deleteSelectedSchedules" color="error" class="px-5">{{
                                    $t('Buttons.Delete')
                                }}</v-btn>
                                <v-btn text @click="dialog.value = false">{{ $t('Buttons.Cancel') }}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </v-container>
        </v-app-bar>
    </section>
</template>

<script>
    import {
        mdiAccountOutline,
        mdiChevronRight,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiFilterVariant,
        mdiMagnify,
        mdiPlus,
        mdiSquareEditOutline,
        mdiTrashCanOutline,
    } from '@mdi/js';
    import { ref } from '@vue/composition-api';

    import useScheduleList from './useSchedulesList';
    import SchedulesFilter from '@/views/schedule/schedules-filter/SchedulesFilter.vue';

    export default {
        components: {
            SchedulesFilter,
        },
        setup() {
            const { tableColumns, scheduleListTable, totalScheduleListTable, fetchSchedulesList } = useScheduleList();

            return {
                tableColumns,
                scheduleListTable,
                totalScheduleListTable,
                fetchSchedulesList,

                // icons
                icons: {
                    mdiSquareEditOutline,
                    mdiFileDocumentOutline,
                    mdiDotsVertical,
                    mdiDeleteOutline,
                    mdiPlus,
                    mdiExportVariant,
                    mdiAccountOutline,
                    mdiMagnify,
                    mdiChevronRight,
                    mdiFilterVariant,
                    mdiTrashCanOutline,
                },
            };
        },
        created() {
            this.isLoading = true;
            this.$store.commit('app-schedules/resetSchedulesQuery');
            this.query = this.$store.getters['app-schedules/getSchedulesQuery'];

            this.fetchSchedulesList();
            setTimeout(() => {
                this.isLoading = false;
            }, 3000);
        },
        data() {
            return {
                search: null,
                selected: [],
                isFiltersActive: false,
                isLoading: false,
                isSuccess: false,
                isError: false,
                query: {},
                options: {
                    page: 1,
                },
            };
        },

        methods: {
            getScheduleList() {
                this.isLoading = true;
                this.fetchSchedulesList();

                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },

            deleteSelectedSchedules() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-schedules/deleteManySchedules', {
                        where: {
                            id: { $in: this.selected.map((item) => item.id) },
                        },
                    })
                    .then((response) => {
                        this.isSuccess = true;
                        this.$store.commit('app-schedules/setIsDeleteSchedule', true);

                        this.fetchSchedulesList();
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 2000);
                    })
                    .catch((error) => {
                        this.isError = true;

                        setTimeout(() => {
                            this.isLoading = false;
                        }, 1000);
                    });
                this.selected = [];
            },

            searchSchedules() {
                this.isLoading = true;
                this.$store.commit('app-schedules/setScheduleSearch', this.search);
                this.query = this.$store.getters['app-schedules/getSchedulesQuery'];

                this.query.pagination.page = 1;
                this.fetchSchedulesList();
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },

            paginationHandle(e) {
                if (this.options.page > this.query.pagination.page) {
                    this.query.pagination.page = this.options.page;
                    this.$store.commit('app-schedules/setSchedulesQuery', this.query);
                    this.fetchSchedulesList();
                }
            },
        },
    };
</script>

<style lang="scss">
    @import '@core/preset/preset/apps/user.scss';
    @import '@/styles/variables.scss';
    progress-bar {
        background-color: #58585836;
    }
    .trash-icon {
        width: 60rem;
        height: 60rem;
    }
</style>
